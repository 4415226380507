<template>
  <div class="PlayersContainer">
    <div class="PlayersContainer__title">{{ $t('PLAYERS_TOPIC') }}</div>
    <div v-if="false" class="PlayersContainer__close" @click="displayPlayers(false)"></div>
    <div class="PlayersContainer__slider">
      <div class="Players">
        <div class="Players__item" v-for="item in players" @click="loadPlayer(item)" :key="item.name">
          <div class="Players__item__picture"
               :style="{ backgroundImage: `url(${playerImage(item.picture)})` }"></div>
          <div class="Players__item__name">{{ item.name }}</div>
          <div class="Players__item__duration">({{ item.duration }})</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import replace from 'lodash.replace';

export default {
  mixins: [],
  components: {},
  data() {
    return {};
  },
  methods: {
    ...mapMutations(['displayHighlights', 'initialHighLight', 'displayPlayers', 'resetTracking']),
    loadPlayer(item) {
      this.$store.state.player.highlightId = item.id;
      this.resetTracking();
      this.player.kind = 'Player';
      this.playerInstance.src({
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: item.stream_hls,
      });
      this.displayPlayers(false);
      this.playerInstance.play();
    },
    playerImage(img) {
      return replace(img, '{$width}', '400');
    },
  },
  computed: {
    ...mapState(['highlights', 'playlist', 'players', 'playerInstance', 'player']),
    ...mapGetters(['scaleImage']),
  },
};
</script>
<style lang="scss">

</style>
