<template>
  <div class="PlayerHighlights" :class="{ open: panel }"
       :style="{ transform: `translateY(${amount})`}">
    <div class="PlayerHighlights__items">
      <div class="PlayerHighlights__items__item"
           v-for="item in playerHighlights.player_highlights.plays" @click="loadPlays(item)"
           :key="item.id">
        <div class="PlayerHighlights__items__item__btn">{{ item.name }} <span
          class="text-light d-none d-sm-inline">({{ item.duration }})</span></div>
      </div>
    </div>
    <div class="PlayerHighlights__title" @click="onOverPanel">
      <div class="PlayerHighlights__title__img" :class="{ open: panel }"
           :style="{backgroundImage: `url(${replace(playerHighlights.cover, '{$width}', '324')})`}"></div>
      <div class="PlayerHighlights__title__name">{{ playerHighlights.title }}</div>
      <div class="PlayerHighlights__title__handle" :class="{ open: panel }">
        <svg viewBox="0 0 448 512" class="svg-inline--fa fa-chevron-down fa-w-14 fa-3x">
          <path fill="#ffffff"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                class=""></path>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState, mapMutations } from 'vuex';
import { replace } from 'lodash';

export default {
  data() {
    return {
      replace,
      panel: true,
      reset: false,
      amount: 0,
    };
  },
  methods: {
    ...mapMutations(['displayPlayerHighlights', 'setPlayerInfo', 'resetTracking']),
    onOverPanel() {
      this.panel = !this.panel;
    },
    loadPlays(item) {
      this.$store.state.player.highlightId = item.id;
      this.resetTracking();
      this.player.kind = 'Player Highlight';
      this.playerInstance.src({
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: item.stream_hls,
      });
      this.displayPlayerHighlights(true);
      this.panel = false;
      this.playerInstance.play();
    },
  },
  watch: {
    panel(val) {
      try {
        const h = document.querySelector('.PlayerHighlights__items')
          .getBoundingClientRect().height + 10;
        if (!val) {
          this.amount = `-${h}px`;
          return;
        }
        this.amount = '0';
      } catch (e) {
        this.amount = '0';
      }
    },
  },
  computed: {
    ...mapState(['playerHighlights', 'playerInstance', 'player', 'colors', 'highlights_on']),
  },
};
</script>
<style lang="scss">
.PlayerHighlights {
  background-color: rgba(0, 0, 0, .4);

  &.open {
    background-color: rgba(0, 0, 0, .8);
  }

  &__title {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;

    &__img {
      transition: all 0.5s ease;
      background-position: center;
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 50px;

      &.open {
        width: 6vw;
        height: 6vw;
        border-radius: 20vw;
      }
    }

    &__name {
      transition: all 0.5s ease;
      * {
        transition: all 0.5s ease;
      }
      text-align: left;
      font-size: 20px;
      padding: 10px;
    }

    &__handle {
      width: 30px;
      // background-color: rgba(0, 0, 0, .9);
      svg {
        transition: all 0.5s ease;
        width: 30px;
        transform: rotate(360deg);
      }

      &.open {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0);

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

</style>
