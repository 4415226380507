<template>
  <div class="Highlights">
    <div v-if="false" class="Highlights__close" @click="displayHighlights(false); reset = true;">
    </div>
    <div class="Highlights__title">{{ $t('HIGHLIGHTS_TOPIC') }}</div>
    <div class="Highlights__time">{{ $tc('HIGHLIGHTS_DURATION_MINUTES', sliderValue, { minutes:
      sliderValue}) }}
    </div>
    <p style="{color: white}">{{ $tc('apple', 10, { count: 10 }) }}</p>
    <div class="sliderContainer">
      <vue-slider
        ref="slider"
        v-model="sliderValue"
        :data="this.minutes"
        :disabled="false"
        :tooltip="false"
        width="100%"
      ></vue-slider>
    </div>
    <div class="Highlights__button">
      <button @click="playHighlight()">{{ $t('WATCH_ACTION') }}</button>
    </div>
  </div>
</template>
<script>
import vueSlider from 'vue-slider-component';
import { mapState, mapMutations } from 'vuex';
import find from 'lodash.find';
import forEach from 'lodash.foreach';
import forIn from 'lodash.forin';
// import anime from 'animejs';

export default {
  data() {
    return {
      sliderValue: 0,
      urlMap: {},
      reset: false,
    };
  },
  beforeMount() {
    this.sliderValue = 0;
  },
  updated() {
    this.$refs.slider.refresh();
  },
  mounted() {
    this.createUrlMap();
    setTimeout(() => {
      // eslint-disable-next-line no-debugger
      debugger;
      this.sliderValue = parseInt(find(this.smartHighlights, { default: true }).name, 10);
    }, 500);
  },
  activated() {
    this.createUrlMap();
  },
  methods: {
    ...mapMutations(['displayHighlights', 'setPlayerInfo', 'resetTracking']),
    loadHighlight() {
      const el = this.urlMap[this.sliderValue];
      this.$store.state.player.highlightId = el.id;
      this.playerInstance.src({
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: el.stream_hls,
      });
    },
    playHighlight() {
      this.loadHighlight();
      this.displayHighlights(false);
      this.resetTracking();
      this.playerInstance.play();
    },
    createUrlMap() {
      forEach(this.smartHighlights, (h) => {
        this.urlMap[parseInt(h.name, 10)] = h;
      });
    },
  },
  computed: {
    ...mapState(['smartHighlights', 'playerInstance', 'player', 'colors', 'highlights_on']),
    minutes() {
      const minutes = [];
      forIn(this.smartHighlights, (h) => {
        minutes.push(parseInt(h.name, 10));
      });
      return minutes;
    },
  },
  components: {
    vueSlider,
  },
};
</script>
