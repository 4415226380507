<template>
  <div class="Plays">
    <div v-if="false" class="Plays__close" @click="displayPlays(false)"></div>
    <div class="Plays__title">
      {{ $t('PLAYS_TOPIC') }}
    </div>
    <div class="Plays__items">
      <div class="Plays__items__item" v-for="item in plays" @click="loadPlays(item)" :key="item.id">
        <div class="Plays__items__item__btn">{{ item.name }} <span
          class="text-light d-none d-sm-inline">({{ item.duration }})</span></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      sliderValue: 1,
    };
  },
  methods: {
    ...mapMutations(['displayHighlights', 'displayPlays', 'resetTracking']),
    loadPlays(item) {
      this.$store.state.player.highlightId = item.id;
      this.resetTracking();
      this.player.kind = 'Play';
      this.playerInstance.src({
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: item.stream_hls,
      });
      this.displayPlays(false);
      this.playerInstance.play();
    },
  },
  computed: {
    ...mapState(['playerInstance', 'highlights', 'plays', 'player']),

  },
  components: {},
};
</script>
