<template>
  <div class="PlayerMenu">
    <div class="PlayerMenu__item" v-if="smartHighlights && smartHighlights.length" :class="{'active': highlights_on}" @click.stop="displayHighlights(true)">{{ $t('HIGHLIGHTS') }}</div>
    <div class="PlayerMenu__item" v-if="plays && plays.length" :class="{'active': plays_on}" @click.stop="displayPlays(true)">{{ $t('PLAYS') }}</div>
    <div class="PlayerMenu__item" v-if="players && players.length" :class="{'active': players_on}" @click.stop="displayPlayers(true)">{{ $t('PLAYERS') }}</div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'PlayerMenu',
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations([
      'displayHighlights',
      'displayPlays',
      'displayPlayers',
    ]),
  },
  computed: {
    ...mapState(['highlights', 'smartHighlights', 'plays', 'players', 'plays_on', 'players_on', 'highlights_on']),
  },
};
</script>
